import fetchWrapper from '@utils/fetch';
import {
  all_players_v2_market_players_path,
  intermediaries_v2_market_players_path,
  v2_buyers_path,
} from '@utils/routes';
import MarketPlayer from '@utils/types/market_player';

export const getBuyerPlayers = async (with_unavailabilities_info?: boolean) => {
  const response = await fetchWrapper(v2_buyers_path({ params: { with_unavailabilities_info } }));
  const marketPlayers: MarketPlayer[] = await response.json();

  return marketPlayers;
};

export const getIntermediaries = async (): Promise<MarketPlayer[]> => {
  const response = await fetchWrapper(intermediaries_v2_market_players_path());

  return response.json();
};

export const getMarketPlayers = async (): Promise<MarketPlayer[]> => {
  const response = await fetchWrapper(all_players_v2_market_players_path({ params: { only_current: true } }));

  return response.json();
};
