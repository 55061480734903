import { getCredentials } from '@api/credentials';
import { getEdfAgencies } from '@api/edf';
import { getIndexFormulas } from '@api/indexation';
import { getAllInstallations } from '@api/installations';
import { getMarketFutures } from '@api/marketFutures';
import { getBuyerPlayers, getIntermediaries } from '@api/marketPlayers';
import { getPriceRules } from '@api/priceRules';
import { useUser } from '@context/User.context';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { formatMarketFutureDate } from '@utils/date';
import { PriceRule } from '@utils/types/contract';
import { Credential } from '@utils/types/credential';
import { EdfAgency } from '@utils/types/edf_agency';
import IndexFormula from '@utils/types/IndexFormula';
import { InstallationWithMeterInfo } from '@utils/types/installation';
import { MarketFuture } from '@utils/types/market_future';
import MarketPlayer from '@utils/types/market_player';

export type ContractFormQueries = {
  marketPlayers: UseQueryResult<MarketPlayer[]>;
  installations: UseQueryResult<InstallationWithMeterInfo[]>;
  intermediaries: UseQueryResult<MarketPlayer[]>;
  cegedimCredentials: UseQueryResult<Credential[]>;
  edfOaCredentials: UseQueryResult<Credential[]>;
  edfAgencies: UseQueryResult<EdfAgency[]>;
  marketFutures: UseQueryResult<MarketFuture[]>;
  priceRules: UseQueryResult<PriceRule[]>;
  indexFormulas: UseQueryResult<{
    formulas: IndexFormula[];
    edfFormulas: IndexFormula[];
  }>;
};

// Fetches the data needed for the form, TanStack Query Results are directly passed as props to the form components
// to handle loading states and errors.
export const useContractFormQueries = (startDate?: Date, endDate?: Date): ContractFormQueries => {
  const user = useUser();

  const installations = useQuery({
    queryKey: ['all_installations'],
    queryFn: () => getAllInstallations({ type: 'operational_with_draft', withMeterInfo: true }),
  });

  const marketPlayers = useQuery({
    queryKey: ['marketPlayers'],
    queryFn: () => getBuyerPlayers(),
  });

  const intermediaries = useQuery({
    queryKey: ['intermediaries'],
    queryFn: getIntermediaries,
  });

  const cegedimCredentials = useQuery({
    queryKey: ['credentials', 'cegedim'],
    queryFn: () => getCredentials('cegedim'),
  });

  const edfOaCredentials = useQuery({
    queryKey: ['credentials', 'edf_oa'],
    queryFn: () => getCredentials('edf_oa'),
  });

  const edfAgencies = useQuery({
    queryKey: ['edf_agencies'],
    queryFn: getEdfAgencies,
  });

  const marketFutures = useQuery({
    queryKey: ['market_futures', startDate, endDate],
    queryFn: async () => {
      const data = await getMarketFutures(startDate, endDate);

      return data && Array.isArray(data)
        ? data
            .sort((a: MarketFuture, b: MarketFuture): number => {
              if (a.time_type === b.time_type) {
                return a.start_date.localeCompare(b.start_date);
              }

              return a.time_type.localeCompare(b.time_type);
            })
            .map((marketFuture: MarketFuture) => ({
              ...marketFuture,
              name: formatMarketFutureDate(marketFuture, user.locale),
            }))
        : [];
    },
  });

  const indexFormulas = useQuery({
    queryKey: ['index_formulas'],
    queryFn: getIndexFormulas,
  });

  const priceRules = useQuery({
    queryKey: ['price_rules'],
    queryFn: getPriceRules,
  });

  return {
    marketPlayers,
    installations,
    intermediaries,
    cegedimCredentials,
    edfOaCredentials,
    edfAgencies,
    marketFutures,
    priceRules,
    indexFormulas,
  };
};
